/*#region Navbar Helpers */
/*#endregion */
.navbar-tm {
  background: #fff;
}
.shrink.navbar-tm {
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0 0 5px #aaa;
}
.navbar-tm .navbar-header .navbar-brand img {
  height: 1.75em;
  -webkit-transition: all .25s linear 0s;
          transition: all .25s linear 0s;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .navbar-tm .navbar-header .navbar-brand img {
    height: 2.75em;
    margin-top: .25em;
  }
  .shrink.navbar-tm .navbar-header .navbar-brand img {
    padding-top: 0;
    height: 1.75em;
  }
}
@media (min-width: 1200px) {
  .navbar-tm .navbar-header .navbar-brand img {
    height: 3.75em;
  }
  .shrink.navbar-tm .navbar-header .navbar-brand img {
    padding-top: 0;
    height: 2em;
  }
}
.navbar-tm .navbar-nav {
  /*> .open {
      > a, > a:hover, > a:focus {
        background-color: transparent;
        color: #333;
      }
    }*/
}
.navbar-tm .navbar-nav > li > a {
  border-top: .25em solid transparent;
}
@media (min-width: 768px) and (max-width: 991px) {
  .navbar-tm .navbar-nav > li > a {
    font-size: 14px;
    padding-left: 5px;
    padding-right: 5px;
  }
}
@media (min-width: 992px) {
  .navbar-tm .navbar-nav > li > a {
    padding-top: 1.75em;
    padding-bottom: 2em;
    -webkit-transition: all .25s linear 0s;
            transition: all .25s linear 0s;
    font-size: 16px;
  }
  .shrink.navbar-tm .navbar-nav > li > a {
    padding-top: .75em;
    padding-bottom: 1em;
  }
}
.navbar-tm .navbar-nav > .active > a,
.navbar-tm .navbar-nav > .active > a:hover,
.navbar-tm .navbar-nav a:hover,
.navbar-tm .navbar-nav a:focus {
  background: transparent;
  border-top: 0.25em solid #0B72B5;
}


@font-face {
  font-family: 'tmd';
  src: url('/fonts/tmd.eot?35ektu');
  src: url('/fonts/tmd.eot?#iefix35ektu') format('embedded-opentype'), url('/fonts/tmd.woff?35ektu') format('woff'), url('/fonts/tmd.ttf?35ektu') format('truetype'), url('/fonts/tmd.svg?35ektu#tmd') format('svg');
  font-weight: normal;
  font-style: normal;
}
[class^="icon-"],
[class*=" icon-"] {
  font-family: 'tmd';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-mobile:before {
  content: "\e600";
}
.icon-paperclip:before {
  content: "\e601";
}
.icon-user:before {
  content: "\e602";
}
.icon-location:before {
  content: "\e603";
}
.icon-share:before {
  content: "\e604";
}
.icon-quote:before {
  content: "\e605";
}
.icon-house:before {
  content: "\e606";
}
.icon-pictures:before {
  content: "\e607";
}
.icon-video:before {
  content: "\e608";
}
.icon-arrow-left:before {
  content: "\e609";
}
.icon-arrow-down:before {
  content: "\e60a";
}
.icon-arrow-up:before {
  content: "\e60b";
}
.icon-arrow-right:before {
  content: "\e60c";
}
.icon-arrow-left2:before {
  content: "\e60d";
}
.icon-arrow-down2:before {
  content: "\e60e";
}
.icon-arrow-up2:before {
  content: "\e60f";
}
.icon-uniE610:before {
  content: "\e610";
}
.icon-facebook:before {
  content: "\e611";
}
.icon-googleplus:before {
  content: "\e612";
}
/*#region Navbar Helpers */
/*#endregion */
.navbar-tm {
  background: #fff;
}
.shrink.navbar-tm {
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0 0 5px #aaa;
}
.navbar-tm .navbar-header .navbar-brand img {
  height: 1.75em;
  -webkit-transition: all .25s linear 0s;
          transition: all .25s linear 0s;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .navbar-tm .navbar-header .navbar-brand img {
    height: 2.75em;
    margin-top: .25em;
  }
  .shrink.navbar-tm .navbar-header .navbar-brand img {
    padding-top: 0;
    height: 1.75em;
  }
}
@media (min-width: 1200px) {
  .navbar-tm .navbar-header .navbar-brand img {
    height: 3.75em;
  }
  .shrink.navbar-tm .navbar-header .navbar-brand img {
    padding-top: 0;
    height: 2em;
  }
}
.navbar-tm .navbar-nav {
  /*> .open {
      > a, > a:hover, > a:focus {
        background-color: transparent;
        color: #333;
      }
    }*/
}
.navbar-tm .navbar-nav > li > a {
  border-top: .25em solid transparent;
}
@media (min-width: 768px) and (max-width: 991px) {
  .navbar-tm .navbar-nav > li > a {
    font-size: 14px;
    padding-left: 5px;
    padding-right: 5px;
  }
}
@media (min-width: 992px) {
  .navbar-tm .navbar-nav > li > a {
    padding-top: 1.75em;
    padding-bottom: 2em;
    -webkit-transition: all .25s linear 0s;
            transition: all .25s linear 0s;
    font-size: 16px;
  }
  .shrink.navbar-tm .navbar-nav > li > a {
    padding-top: .75em;
    padding-bottom: 1em;
  }
}
.navbar-tm .navbar-nav > .active > a,
.navbar-tm .navbar-nav > .active > a:hover,
.navbar-tm .navbar-nav a:hover,
.navbar-tm .navbar-nav a:focus {
  background: transparent;
  border-top: 0.25em solid #0B72B5;
}
.shadow {
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
}
body {
  position: relative;
}
.page {
  min-height: 100vh;
  padding-bottom: 4em;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
}
.page .intro {
  font-size: 1.25em;
  text-align: center;
  padding: 3em 0;
}
@media only screen and (min-width: 768px) {
  .page .intro {
    font-size: 1.75em;
    padding-top: 5em;
  }
}
.page .intro h1,
.page .intro h2 {
  font-weight: 300;
}
@media only screen and (min-width: 768px) {
  .page .intro h1,
  .page .intro h2 {
    font-size: 3em;
    
  }
}
.page .intro p {
  font-weight: 300;
  color: #fff;
}
.page .intro em {
  font-style: italic;
  font-weight: 400;
  
}
.page .tm {
  text-align: center;
}
.page .tm .img-tm {
  width: 250px;
  margin: 0 auto;
}
.page a {
  text-decoration: none;
  border-bottom: 2px solid transparent;
  -webkit-transition: all 0.25s linear 0s;
          transition: all 0.25s linear 0s;
  color: #0B72B5;
}
.page a:hover {
  border-bottom: 2px solid #0B72B5;
}
.page h1,
.page h2,
.page h3,
.page h4 {
  font-weight: 300;
}
.page h2,
.page h3,
.page h4 {
  text-transform: uppercase;
}
.sm-links {
  font-size: 32px;
}
.sm-links a {
  color: #fff;
}
#home-page {
  background-color: #444;
 /* Old browsers */
  /* FF3.6+ */
  background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%, #888), color-stop(100%, #444));
  /* Chrome,Safari4+ */
  background: -webkit-radial-gradient(center, ellipse cover, #888 0%, #444 100%);
  /* Chrome10+,Safari5.1+ */
  /* Opera 12+ */
  /* IE10+ */
  background: -webkit-radial-gradient(center, ellipse, #888 0%, #444 100%);
  background: radial-gradient(ellipse at center, #888 0%, #444 100%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#888', endColorstr='#444', GradientType=1);
  /* IE6-9 fallback on horizontal gradient */
  height: auto;
  min-height: unset;
 
}
#home-page .intro {
  color: #fff;
  text-shadow: 1px 1px 2px #000;
}
#home-page .marti {
  color: #fff;
  text-shadow: 1px 1px 2px #000;
}


#dorn-methode {
  background-color: #fff;
}
#dorn-methode .intro p{
  color: #000;
 
}
#dorn-methode .txt {
  background: #fff;
  padding: 2em;
  border-radius: 15px;
  font-size: 1.2em;
}
#lastposts-page {
  background: #eaeaea;
  color: #555;
  height: auto;
  min-height: 5em;
}
#lastposts-page .intro {
  font-size: 1em;
}
#wirbelsaeule-page {
  background: #0B72B5;
  /* Old browsers */
  /* FF3.6+ */
  background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%, #549AC8), color-stop(100%, #0B72B5));
  /* Chrome,Safari4+ */
  background: -webkit-radial-gradient(center, ellipse cover, #549AC8 0%, #0B72B5 100%);
  /* Chrome10+,Safari5.1+ */
  /* Opera 12+ */
  /* IE10+ */
  background: -webkit-radial-gradient(center, ellipse, #549AC8 0%, #0B72B5 100%);
  background: radial-gradient(ellipse at center, #549AC8 0%, #0B72B5 100%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#549AC8', endColorstr='#0B72B5', GradientType=1);
  /* IE6-9 fallback on horizontal gradient */
  height: auto;
  min-height: unset;
  color: #fff;
  text-shadow: 1px 1px 2px #000;
}
#wirbelsaeule-page a {
  color: #fff;
  border-bottom: 2px solid #0B72B5;
}
#wirbelsaeule-page a:hover {

  border-bottom: 2px solid #fff;
}
#wirbelsaeule-page a.wirbel {
  border: none;
  text-decoration: none;
}
#wirbelsaeule-page a.wirbel:hover {
  border: none;
  text-decoration: none;
}
#wirbelsaeule-page .img-wirbel {
  max-height: 25vh;
  padding-top: 2em;
}
@media only screen and (min-width: 992px) {
  #wirbelsaeule-page .img-wirbel {
    max-height: 100vh;
  }
}
#wirbelsaeule-page blockquote {
  color: #000;
  text-shadow: none;
  background: none repeat scroll 0 0 rgba(255, 255, 255, 0.8);
  margin-bottom: 3em;
}
#wirbelsaeule-page blockquote footer {
  color: #0B72B5;
  display: block;
  font-size: 80%;
  line-height: 1.643;
}
#wirbelsaeule-page .img-tm-wrap {
  width: auto;
  height: auto;
  margin-left: 1.5em;
  border-radius: 7px;
}
#wirbelsaeule-page .img.wrap {
  text-shadow: none;
}
#wirbelsaeule-page .img.wrap h2 {
  color: #000;
}
.pad {
  padding-bottom: 0.5em;
}
#preise-page {
  background: #fff; 
}
#preise-page .intro p {
  color: #000;
}

#preise-page .offerbox {
  /*background: #fff;*/
  border-radius: 15px;
  border: 4px solid #fff;
}
#preise-page .offerbox > p {
  padding: 0 1.5em;
}
#preise-page .offerbox p {
  border-radius: 0;
  padding: 0.4em 0 4 0;
  /*text-align: right;*/
}

#preise-page .offerbox p strong {
  font-size: 2em;
  font-weight: 300;  
}
#preise-page .offerbox strong {
  /*font-size: 2em;
  font-weight: 300;*/
  font-weight: 600;
}
#preise-page .offerbox h3 {
  background: #0B72B5;
  border-radius: 15px 15px 0 0;
  color: #fff;
  margin-top: 0;
  padding: 0.4em 0;
}

#preise-page .offerbox.mini {
  -webkit-transform: scale(0.8);
      -ms-transform: scale(0.8);
          transform: scale(0.8);
  position: relative;
  /*&.right {
        left: -2em;
      }

      &.left {
        left: 2em;
      }*/
}
#preise-page .bg-light {
  background: #fff;
  background: rgba(255, 255, 255, 0.5);
}


#gutschein-page {
  height: auto;
  min-height: unset;
  padding-top: 2em;
  background-color: #444;
 /* Old browsers */
  /* FF3.6+ */
  background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%, #888), color-stop(100%, #444));
  /* Chrome,Safari4+ */
  background: -webkit-radial-gradient(center, ellipse cover, #888 0%, #444 100%);
  /* Chrome10+,Safari5.1+ */
  /* Opera 12+ */
  /* IE10+ */
  background: -webkit-radial-gradient(center, ellipse, #888 0%, #444 100%);
  background: radial-gradient(ellipse at center, #888 0%, #444 100%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#888', endColorstr='#444', GradientType=1);
  /* IE6-9 fallback on horizontal gradient */
  height: auto;
  min-height: unset;
  color: #fff;
  text-shadow: 1px 1px 2px #000;
  
}
#gutschein-page header.intro {
  display: none;
}
#gutschein-page hgroup {
  margin: 2em 0;
}
#gutschein-page hgroup h2,
#gutschein-page hgroup h3 {
  margin: .25em;
}
#gutschein-page .img-gutschein {
  border-radius: 15px;
  height: auto;
  -webkit-transform: rotate(-10deg);
      -ms-transform: rotate(-10deg);
          transform: rotate(-10deg);
  width: auto;
  margin: 2em auto;
}
#ueber-mich-page {
  background: #0B72B5;
  /* Old browsers */
  /* FF3.6+ */
  background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%, #549AC8), color-stop(100%, #0B72B5));
  /* Chrome,Safari4+ */
  background: -webkit-radial-gradient(center, ellipse cover, #549AC8 0%, #0B72B5 100%);
  /* Chrome10+,Safari5.1+ */
  /* Opera 12+ */
  /* IE10+ */
  background: -webkit-radial-gradient(center, ellipse, #549AC8 0%, #0B72B5 100%);
  background: radial-gradient(ellipse at center, #549AC8 0%, #0B72B5 100%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#549AC8', endColorstr='#0B72B5', GradientType=1);
  /* IE6-9 fallback on horizontal gradient */
  height: auto;
  min-height: unset;
  color: #fff;
  text-shadow: 1px 1px 2px #000;
}
#cajacob-page {
  background-color: #444;
 /* Old browsers */
  /* FF3.6+ */
  background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%, #888), color-stop(100%, #444));
  /* Chrome,Safari4+ */
  background: -webkit-radial-gradient(center, ellipse cover, #888 0%, #444 100%);
  /* Chrome10+,Safari5.1+ */
  /* Opera 12+ */
  /* IE10+ */
  background: -webkit-radial-gradient(center, ellipse, #888 0%, #444 100%);
  background: radial-gradient(ellipse at center, #888 0%, #444 100%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#888', endColorstr='#444', GradientType=1);
  /* IE6-9 fallback on horizontal gradient */
  height: auto;
  min-height: unset;
  color: #fff;
  text-shadow: 1px 1px 2px #000;
}

#kontakt-page {
  position: relative;
}
.img-center {
  margin: 0 auto;
}
#map {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.tm-tooltip {
  font-size: 16px;
  padding: 2rem;
}
.tm-tooltip h2 {
  margin: 0;
}
.bubble {
  margin: -4px 0;
}
.img-tm-wrap {
  width: 200px;
  height: 200px;
  max-width: 100%;
  margin: 0 auto;
  overflow: hidden;
  border: 4px solid #fff;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
  /*box-shadow: 0 0 5px #333;*/
}
.offerbox {
  text-align: center;
  margin-bottom: 2em;
}
.offerbox .img-tm-wrap {
  margin: 0 auto -10rem;
} 
.offerbox h3 {
  font-size: 1.5em;
  margin-bottom: 1em;
}
.offerbox a,
.offerbox .inner {
  color: #000;
  text-decoration: none;
  background: #fff;
  display: block;
  padding: 2em;
  border-radius: 15px;
  -webkit-transition: all 0.25s linear 0s;
          transition: all 0.25s linear 0s;
}
.offerbox a:hover,
.offerbox .inner:hover {
  background: #0B72B5;
  color: #fff;
  border: none;
}
#preise .offerbox .inner {
  background: #0085CD;
  color: #fff ;
}
#abo .offerbox .inner {
  background: #f5a154;
  color: #000 ;
  text-shadow:none;
}
.btn-cta {
  background: #fff;
  color: #0B72B5 !important;
  border-radius: 15px;
  border: 4px solid #fff !important;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
  text-shadow: none;
  white-space: normal;
}
.pricebox {
  text-align: center;
margin: 0 auto -2rem;
  /*margin-bottom: 14rem;*/

}
.pricebox .preis {
  background: #ff8300;
  border-radius: 50%;
  width: 170px;
  height: 170px;
  margin: 0 auto;
  /*margin: 0 auto -6rem;*/
  display: block;
  vertical-align: middle;
  font-size: 48px;
  color: #fff;
  line-height: 1.2;
  position: relative;
  -webkit-transform: scale(0.75);
      -ms-transform: scale(0.75);
          transform: scale(0.75);
}
.pricebox .preis.img-tm-wrap {
  border-width: 5px;
}
.pricebox .preis .inner {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.pricebox .preis small {
  font-size: 16px;
  display: block;
}
@media only screen and (min-width: 1441px) {
  body {
    font-size: 18px;
  }
  .container {
    width: 90%;
    max-width: 1600px;
  }
}
blockquote {
  background: rgba(255, 255, 255, 0.5);
  border: medium none;
  border-radius: 15px;
  margin: 8rem 0 0;
  padding: 3rem 4rem;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
}
blockquote:before,
blockquote:after {
  font-family: serif;
  font-size: 18rem;
  line-height: 0;
  color: #0B72B5;
}
blockquote:before {
  content: "“";
  margin-left: 0rem;
  position: absolute;
}
blockquote:after {
  content: "„";
  float: right;
  position: relative;
  top: -2.5rem;
}
.img.wrap {
  background: #fff;
  border-radius: 15px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
  padding: 2rem;
  text-align: center;
}
.img.wrap > h2 {
  font-size: 24px;
}
.calendar h3 {
  font-size: 16px;
}
.page.container {
  padding-top: 150px;
  min-height: 1em;
}
body > footer {
  background: #0B72B5;
  /* Old browsers */
  /* FF3.6+ */
  background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%, #549AC8), color-stop(100%, #0B72B5));
  /* Chrome,Safari4+ */
  background: -webkit-radial-gradient(center, ellipse cover, #549AC8 0%, #0B72B5 100%);
  /* Chrome10+,Safari5.1+ */
  /* Opera 12+ */
  /* IE10+ */
  background: -webkit-radial-gradient(center, ellipse, #549AC8 0%, #0B72B5 100%);
  background: radial-gradient(ellipse at center, #549AC8 0%, #0B72B5 100%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#549AC8', endColorstr='#0B72B5', GradientType=1);
  /* IE6-9 fallback on horizontal gradient */
  height: auto;
  min-height: unset;
  color: #fff;
  text-shadow: 1px 1px 2px #000;
}
body > footer .row {
  padding: 120px 0 2em;
}
@media only screen and (min-width: 768px) {
  body > footer .row {
    padding: 2em 0;
  }
}
body > footer a {
  color: #fff;
  border-bottom: 2px solid #0B72B5;
}
body > footer a:hover {
  border-bottom: 2px solid #fff;
}
body > footer dl dt {
  font-weight: 300;
}
body > footer dl dd {
  font-size: smaller;
}
body > footer img.colibri {
  width: 200px;
  position: absolute;
  left: 50%;
  margin-left: -100px;
  margin-top: -75px;
}
