
.preisliste {
	$abstand: 2rem;
    
    td {
        padding: 0;
        vertical-align: top;
    }

	.preis {
		padding-right: $abstand;
		border-right: 1px solid #000;
        font-size: 34px;
        line-height: 1.125;
	}

	.text {
		padding-left: $abstand;

        h2 {
            margin-top: 0;
            padding-top: 0;
        }
	}

	.spacer {
		padding-block: $abstand / 2;
	}
}